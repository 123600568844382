<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <div class="media-body"><span class="me-2">{{ userName ? (userName | titlecase) : 'Admin' }}</span>
    <p class="mb-0 font-roboto">{{userRole}}</p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li><a href="/faq" [routerLink]="['/setting-page/edit-profile']"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
  <!-- <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li> -->
</ul>