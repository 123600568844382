import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionComponent } from '../shared/components/subscription/subscription.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private modalService: NgbModal) {}

  showSubscriptionExpiredModal() {
    const modalRef: NgbModalRef = this.modalService.open(SubscriptionComponent, {
      centered: true,
      keyboard: false,      
      backdrop: 'static',   
      windowClass: 'subscription-modal'
    });
  }
}