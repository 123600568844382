// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://cloth-rental-backend.onrender.com/',
  imgUrl: 'https://cloth-rental.frontbitsolutions.com/',
  copyRights: 'Copyright 2024 © Cloth Rental',
  googleClientId: '891591283392-9escvclqfg4fpmh07le0b8s3k72m381j.apps.googleusercontent.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
