import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, of, throwError } from 'rxjs';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router, private toastr: ToastrService,private subscriptionService: SubscriptionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.loginService.getToken();
    let route = this.router.url.split('/')[1];
    const page_id = localStorage.getItem('page_id');
    let store_id = JSON.parse(localStorage.getItem('selected_store'))?.id || 3;
    store_id = store_id.toString();
    if (token && route === 'store') {
      const isFormData = request.body instanceof FormData;
      
      let headers = new HttpHeaders({
        'Authorization': token,              
        'store_id': store_id,
        'page_id': page_id,
        'refresh': '0'
      });

      if(isFormData) {
        headers = headers.delete('Content-Type');
      }else{
        headers = headers.set('Content-Type', 'application/json');
      }

      const clone = request.clone({ headers });
      return next.handle(clone).pipe(
        catchError((error: any) => {
          if (error.status === 423) {
            const retryHeaders = headers.set('refresh', '1');
            const retryRequest = request.clone({ headers: retryHeaders });
            this.subscriptionService.showSubscriptionExpiredModal();
            return next.handle(retryRequest).pipe(
              catchError(() => of()) 
            );
          } else if (error.status === 401) {
            this.loginService.logout();
            this.toastr.error('Staff blocked. Please contact your administrator.');
            return throwError(() => error);
          } else if (error.status === 404) {
            this.loginService.logout();
            this.toastr.error('Staff not found. Please contact your administrator.');
            return throwError(() => error);
          }
          return throwError(() => error);
        })
      );
    }
  
    return next.handle(request);
  }

}