import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { AdminLoginService } from 'src/app/services/admin-login.service';
import { LoginService } from 'src/app/services/login.service';
import { OrganizationLoginService } from 'src/app/services/organization-login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  data: any;
  is_washerman_show: boolean;
  userType:any
  ngOnInit(){
    this.is_washerman_show = JSON.parse(localStorage.getItem('is_washing_added'));
    this.userType = this.router.url.split('/')[1];
  }
  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  constructor(private router: Router, public navServices: NavService,
    public layout: LayoutService,private loginService : LoginService, private adminLoginService: AdminLoginService, private organizationService: OrganizationLoginService) {
      this.userType = this.router.url.split('/')[1]
      if(this.userType === 'store') {
        this.data = this.navServices.items;
      } else if(this.userType === 'admin') {
        this.data = this.navServices.admin_items;
      } else if(this.userType === 'organization') {
        this.data = this.navServices.organization_items;
      }
    this.data.subscribe(menuItems => {
      this.menuItems = menuItems;
      let pagesData = JSON.parse(localStorage.getItem('pages'));
      if(this.userType === 'store') {
        // hide-show menu based on view permission
        this.menuItems.forEach((item) => {
          const permission = pagesData.find(permission => permission.page_name === item.pageType);
          if (permission && permission.is_view && !item.children) {
            item.is_view = true;
          } else {
            // sub menu view permission
            if (item.children && item.children.length > 0) {
              this.subNodePermission(item.children, pagesData);
            }
            // children node main menu 
            if(item.children) {
              let child =  item.children.some((item: any) => item.is_view === true);
              item.is_view = child;
            } else {
              item.is_view = false;
            }
          }
        });
      }

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  subNodePermission(subMenu: any, permissionsData: any){
    subMenu.forEach(menuItem => {
      const permission = permissionsData.find(permission => permission.page_name === menuItem.pageType);
      if (permission && permission.is_view) {
        menuItem.is_view = true;
      } else {
        menuItem.is_view = false;
      }
    });
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
   // $event.stopPropagation();
     this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.pageItem(item.pageType);
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      } 
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true; 
            submenuItems.active = false;
          }
        });
      }
    });

    if (item.path === '/store/dashboard' && !item.is_view) {
      const nextItem = this.findNextAvailableItem();

      if (nextItem) {
        this.router.navigateByUrl(nextItem.path); 
      }
    }
  }

  findNextAvailableItem(): any {
    for (let menuItem of this.menuItems) {
      if (menuItem.type === 'link' && menuItem.is_view) {
        menuItem.active = true;
        return menuItem; 
      }
      if (menuItem.children) {
        for (let submenuItem of menuItem.children) {
          if (submenuItem.type === 'link' && submenuItem.is_view) {
            menuItem.active = true;
            return submenuItem;
          }
        }
      }
      
    }
  }

// Click Toggle menu
toggletNavActive(item) {
  // console.log('item is active', item);

  if (!item.active) {
      this.menuItems.forEach(a => {
          if (this.menuItems.includes(item)) {
              a.active = false;

              if (item.children && item.children.length > 0) {
                  // Check if the first child is "Washermen" 
                  if (item.children[0].title === 'Washermen' && (!this.is_washerman_show || !item.children[0].is_view)) {
                      item.children.splice(0, 1);
                  }

                  // Remove any other children where is_view is false
                  item.children = item.children.filter(child => child.is_view);
                 
              }
          }

          if (!a.children) { return false; }
   
          a.children.forEach(b => {
              if (a.children.includes(item)) {
                  b.active = false;
              }
          });
      });
  }
  item.active = !item.active;

}


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  pageItem(pageType: string) {
    if(pageType) {
      let pages = JSON.parse(localStorage.getItem('pages'));
      let currentPage = pages.filter((page: any) =>  page.page_name === pageType);
      localStorage.setItem('page_id', currentPage[0].page_id);
    }
  }

  logoutFunc() {
    if (this.userType === 'store') {
      this.loginService.logout(); 
    } else if (this.userType === 'admin') {
      this.adminLoginService.logout(); 
    } else if (this.userType === 'organization') {
      this.organizationService.logout();
    }
  }
}
