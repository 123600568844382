import { Component, EventEmitter, Input, Output} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FollowUpPopupComponent } from 'src/app/components/admin/follow-up-popup/follow-up-popup.component';
import { ViewHistoryPopupComponent } from 'src/app/components/admin/view-history-popup/view-history-popup.component';
import { LoaderService } from '../../services/loader.service';
import { Router } from '@angular/router';
import { ReturnOrderPopupComponent } from 'src/app/components/admin/return-order-popup/return-order-popup.component';
import { MoveToWashingPopupComponent } from 'src/app/components/admin/move-to-washing-popup/move-to-washing-popup.component';
import { WashingProductDetailsComponent } from 'src/app/components/admin/washing-product-details/washing-product-details.component';
import { ReturnProductPopupComponent } from 'src/app/components/admin/return-product-popup/return-product-popup.component';
import { ToastrService } from 'ngx-toastr';
export interface CompanyDB {
  id: number;
  img: string;
  name: string;
  position: string;
  office: string;
  credit: string;
  invoice: string;
  startDate: string;
  salary: string;
  Budget: string;
  ActionClass: string;
  iconClass: string;
  iconColor: string;
  switchState: boolean;
}

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})

export class BasicTableComponent {
  @Input() tableItems: any[] = [];
  @Input() tableHeaders: string[] = [];
  @Input() tableTitle; 
  @Input() pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  @Input() showActions: boolean;
  @Input() tableButton;
  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();
  @Output() buttonClick = new EventEmitter<void>();
  @Output() searchEvent = new EventEmitter<any>();
  @Output() rentSearchEvent = new EventEmitter<any>();
  @Output() dateChangeEvent = new EventEmitter<any>();
  @Input() isSearch:boolean = true;
  @Input() isSearchCompany:boolean
  @Input() istableBtnShow: boolean = true; 
  @Input() isPagination: boolean = true; 
  @Input() totalCount: number; 
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() pageChange:EventEmitter<any> = new EventEmitter<any>();
  @Output() washListClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() returnDataSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshReturnProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProduct: EventEmitter<any> = new EventEmitter<any>();
  @Input() baseUrl: string = ''; 
  @Input() headerPadding: string = '20px 30px';
  @Input() tableType: string;
  @Input() curAccess: any;
  data = {
    offset: 0,
    limit: 10
  }
  searchText: string = '';
  isEnable: boolean = false;
  bookDate: any = '';
  returnDate: any = '';
  orderId: string = '';
  currentPage: number = 1;
  pageSize: number = this.pageSizeOptions[0]; 
  selectedImage: string = '';
  isModalVisible: boolean = false;
  constructor(private modalService: NgbModal , private loader: LoaderService, private router: Router, private toastr: ToastrService){}
  
  ngOnInit() {}

  // onKeyDown(event:KeyboardEvent){
  //  if(event.key==='Enter'){
  //   this.onDeleteItem(1);
  //  }
  // }
  onEditItem(item: any) {
    this.editItem.emit(item);
  }

  onDeleteItem(item: any) {
    this.deleteItem.emit(item);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.data = {
      offset: page - 1,
      limit: this.pageSize
    }
    this.pageChange.emit(this.data);
  }

  onPageSizeChange(value: string) {
    this.pageSize = parseInt(value, 10); 
    const offset = 0; 
    this.currentPage = 1;
    const pageChangeData = { offset: offset, limit: this.pageSize }; 
    this.pageChange.emit(pageChangeData);
  }

  onButtonClick() {
    this.buttonClick.emit();
  }

  onSearch(event: Event) {
    if (!this.searchText.trim()) {
      event.preventDefault();
      return;
   }
    this.searchEvent.emit(this.searchText);
  }

  onRentSearch() {
    let data = {
      order_id: this.orderId,
      book_date: this.bookDate,
      return_date: this.returnDate
    }
    console.log(data);
    this.rentSearchEvent.emit(data);
  }

  onKeyUp(event: any, type: string) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (event.target.value.trim() !== '') {
        if (type === 'common') {
          this.onSearch(event);
        } else if (type === 'order') {
          this.onRentSearch();
        }
      }
    } else if (event.code === 'Backspace') {
      let Val = event.target.value.trim();
      this.inputValueChange.emit(Val);
    }
  }

  onDateChange(event: any, type: string) {
    let data = {
      date: event,
      type: type
    }
    this.dateChangeEvent.emit(data)
  }
  
  followUp(data: any) {
    if(this.curAccess.is_create) {
      const modalRef = this.modalService.open(FollowUpPopupComponent, {backdrop: 'static', keyboard: false});
      modalRef.componentInstance.leadId = data.id;
    } else {
      this.toastr.error(`You don't have permission for create follow up`);
    }
  }  
  
  viewHistory(data: any) {
    const modalRef = this.modalService.open(ViewHistoryPopupComponent, {backdrop: 'static', keyboard: false});
    modalRef.componentInstance.leadId = data.id;
    this.loader.showLoader();
  }

  OnViewItem(data: any) {
    this.router.navigate([`store/view-order-details/${data.id}`]);
  }

  returnItem(data: any) {
    if(this.curAccess.is_edit) {
      const modalRef = this.modalService.open(ReturnOrderPopupComponent, {backdrop: 'static', keyboard: false});
      modalRef.componentInstance.orderId = data.id;
      modalRef.result.then((data: any) => {
        if(data === 'submit') {
          this.refreshProduct.emit();
        }
      });
    } else {
      this.toastr.error(`You don't have permission for return the booked order product`);
    }
  }

  returnProduct(data: any) {
    if(this.curAccess.is_edit) {
      const modalRef = this.modalService.open(ReturnProductPopupComponent, {backdrop: 'static', keyboard: false});
      modalRef.componentInstance.productId = data.id;
      modalRef.result.then((data: any) => {
        if(data === 'submit') {
          this.refreshReturnProduct.emit();
        }
      });
    } else {
      this.toastr.error(`You don't have permission for return the booked buy back product`);
    }
  }

  washForm(data: any) {
   if(this.curAccess.is_edit){
    const modalRef = this.modalService.open(MoveToWashingPopupComponent, {backdrop: 'static', keyboard: false});
    modalRef.componentInstance.productId = data.id;
   } else {
      this.toastr.error(`You don't have permission for product return to washing`);
   }
  }

  returnList(data:any) {
   if(this.curAccess.is_edit) {
    const modalRef = this.modalService.open(WashingProductDetailsComponent, {backdrop: 'static', keyboard: false});
    modalRef.result.then((data: any) => {
      if(data === 'submit') {
         this.returnDataSave.emit();
      }
    });
    modalRef.componentInstance.tableData = data;
   } else {
     this.toastr.error(`You don't have permission for return washed product`);
   }
  }

  onStatusChange(item: any) {
    this.statusChange.emit(item);
  }

  onInputFocus() {
    this.isEnable = true;
  }

  onInputBlur() {
    this.isEnable = false;
  }

  openImageModal(imageUrl: string) {
    this.selectedImage = imageUrl;
    this.isModalVisible = true;
  }

  closeImageModal() {
    this.isModalVisible = false;
  }

}

