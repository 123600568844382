import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';
import { ApiAdminService } from './api-admin.service'; 
import { ToastrService } from 'ngx-toastr';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AdminLoginService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isAuthenticatedSubject.asObservable();
  authStateSubscription: Subscription;
  googleUser: any;
  admin = localStorage.getItem('adminName');
  adminName$:BehaviorSubject<any> = new BehaviorSubject<any>(JSON.parse(this.admin));
  constructor(private router: Router, private loaderService: LoaderService, private apiAdminService: ApiAdminService, private toastr: ToastrService, private authService: SocialAuthService) {}
  login(userName: string, password: string): void {
    let data = {
      user_name: userName,
      password: password
    };
    this.loaderService.showLoader();
    this.apiAdminService.login(data).subscribe({
      next: (res: any) => {
        localStorage.setItem('adminToken', res.data.accessToken);
        localStorage.setItem('adminName', JSON.stringify(res.data?.name));
        localStorage.setItem('adminRole', JSON.stringify(res.data?.role));
        this.adminName$.next(res.data?.name)
        this.isAuthenticatedSubject.next(true);
        this.router.navigate(['/admin/dashboard']);
        this.toastr.success(res.message);
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Admin not found. Please check your credentials.');
        } else {
          this.toastr.error('Admin login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  googleLogin() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    this.authStateSubscription = this.authService.authState.subscribe((user) => {
      if (user && user.email !== this.googleUser) { 
        this.googleUser = user?.email;
        let requestBody = {
          email: this.googleUser
        };
        this.adminEncryptLogin(requestBody);
      }
    });
  }

  adminEncryptLogin(data: any) {
    this.loaderService.showLoader();
    this.apiAdminService.encryptLogin(data).subscribe({
      next: (res: any) => {
        this.adminSocialLogin(res.data);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Please check your credentials.');
        } else {
          this.toastr.error('Admin login failed');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  adminSocialLogin(data: any) {
    let requestBody = { data };
    this.apiAdminService.socialLogin(requestBody).subscribe({
      next: (res: any) => {
        localStorage.setItem('adminToken', res.data.accessToken);
        localStorage.setItem('adminName', JSON.stringify(res.data?.name));
        localStorage.setItem('adminRole', JSON.stringify(res.data?.role));
        this.isAuthenticatedSubject.next(true);
        this.adminName$.next(res.data?.name)
        this.router.navigate(['/admin/dashboard']);
        this.toastr.success(res.message);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Admin not found. Please check your credentials.');
        } else {
          this.toastr.error('Admin login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  logout() {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminName');
    localStorage.removeItem('adminRole');
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['admin/login']);
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    this.authService.signOut().then(() => {
      this.googleUser = null;
    }).catch((error) => {
      console.error('Error during sign out:', error);
    });
  }

  getAdminToken(): string | null {
    return localStorage.getItem('adminToken');
  }

  isAdminLoggedIn(): boolean {
    return this.getAdminToken() !== null;
  }
}
