import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';
import { ApiOrganizationService } from './api-organization.service'; 
import { ToastrService } from 'ngx-toastr';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class OrganizationLoginService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isAuthenticatedSubject.asObservable();
  selectedRole$ =  new BehaviorSubject<string>('Organization');
  authStateSubscription: Subscription;
  roleType: string;
  org = localStorage.getItem('orgName')
  orgName$:BehaviorSubject<any> = new BehaviorSubject<any>(JSON.parse(this.org));
  googleUser: any;
  constructor(private router: Router, private loaderService: LoaderService, private apiOrganizationService: ApiOrganizationService, private toastr: ToastrService,private authService: SocialAuthService) {}

  login(userName: string, password: string): void {
    let data = {
      user_name: userName,
      password: password
    };
    this.loaderService.showLoader();
    this.apiOrganizationService.login(data).subscribe({
      next: (res: any) => {
        localStorage.setItem('organizationToken', res.data.accessToken);
        localStorage.setItem('orgName', JSON.stringify(res.data?.name));
        localStorage.setItem('orgRole', JSON.stringify(res.data?.role));
        localStorage.setItem('expired_at', JSON.stringify(res.data?.expired_at));
        this.isAuthenticatedSubject.next(true);
        this.orgName$.next(res.data?.name);
        this.router.navigate(['/organization/dashboard']);
        this.toastr.success(res.message);
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Organization not found. Please check your credentials.');
        } else {
          this.toastr.error('Organization login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  googleLogin() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }    
    this.selectedRole$.subscribe((role:any) => {
       this.roleType = role;
    })
    this.authStateSubscription = this.authService.authState.subscribe((user) => {
      if (user && user.email !== this.googleUser) { 
        this.googleUser = user?.email;
        let requestBody = {
          email: this.googleUser
        };
        if(this.roleType === 'Organization') {
          this.orgEncryptLogin(requestBody);
        }
      }
    })
  }

   orgEncryptLogin(data: any) {
    this.loaderService.showLoader();
    this.apiOrganizationService.encryptLogin(data).subscribe({
      next: (res: any) => {
        this.orgSocialLogin(res.data);
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Please check your credentials.');
        } else {
          this.toastr.error('Organization login failed');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  orgSocialLogin(data: any) {
    let requestBody = { data };
    this.apiOrganizationService.socialLogin(requestBody).subscribe({
      next: (res: any) => {
        localStorage.setItem('organizationToken', res.data.accessToken);
        localStorage.setItem('orgName', JSON.stringify(res.data?.name));
        localStorage.setItem('orgRole', JSON.stringify(res.data?.role));
        this.isAuthenticatedSubject.next(true);
        this.orgName$.next(res.data?.name);
        this.router.navigate(['/organization/dashboard']);
        this.toastr.success(res.message);
        localStorage.setItem('expired_at', JSON.stringify(res.data?.expired_at));
        this.loaderService.hideLoader();
      }, error: (err: any) => {
        if (err.status === 404) {
          this.toastr.error('Organization not found. Please check your credentials.');
        } else {
          this.toastr.error('Organization login failed. Please try again later.');
        }
        this.loaderService.hideLoader();
      }
    });
  }

  logout(): void {
    localStorage.removeItem('organizationToken');
    localStorage.removeItem('orgName');
    localStorage.removeItem('orgRole');
    localStorage.removeItem('expired_at');
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['login']);
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    this.authService.signOut().then(() => {
      this.googleUser = null;
    })
    .catch((error) => {});
  }

  getOrganizationToken(): string | null {
    return localStorage.getItem('organizationToken');
  }

  isOrganizationLoggedIn(): boolean {
    return this.getOrganizationToken() !== null;
  }
}
