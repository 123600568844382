import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from '../../services/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private loader: LoaderService,
    public activeModal: NgbActiveModal 
  ) {}

  ngOnInit() {
    this.loadStoredSubscriptionDate();
  }

  loadStoredSubscriptionDate() {
   localStorage.getItem('subscriptionExpirationDate');
  }

  refreshSubscriptionDate() {
    this.loader.showLoader();
    this.apiService.fetchNewSubscriptionDate().subscribe({
      next: (res: any) => {
        this.loader.hideLoader();
        const expirationDate = res.data.expire_date;
        localStorage.setItem('subscriptionExpirationDate', expirationDate);
        this.toastr.success('Subscription date updated successfully');
        this.activeModal.close();
      },
      error: () => {
        this.loader.hideLoader();
        this.toastr.error('Failed to refresh subscription date');
      }
    });
  }

  refresh() {
    this.refreshSubscriptionDate(); 
  }
}
